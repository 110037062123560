<form [formGroup]="registerFG" (ngSubmit)="saveForm()">
    <div class="row justify-content-center mt-5">
        <div class="col-xl-6">
            <mat-form-field class="full-width">
                <span>Contraseña antigua</span>
                <input matInput type="password" formControlName="password" required >
            </mat-form-field>
            <mat-form-field class="full-width" style="margin-top: 3rem;">
                <span>Nueva contraseña</span>
                <input matInput type="password" formControlName="repassword" required>
            </mat-form-field>
        </div>

        <!-- Submit Button -->
        <div class="col-xl-7 col-12" style="margin-top: 5rem;">
            <button [disabled]="!registerFG.valid" type="submit" class="full-width bg-main text-white btn">
                Guardar
            </button>
        </div>
    </div>
</form>