<section class="container-fluid pt-3 max-he over-h">
  <section class="container-fluid max-he">
    <h3>{{title}}</h3>
    <div class="row row justify-content-around">
      <div class="col-xl-6">
        Paciente
        <select name="" id="" class="form-control" [disabled]="disabled" [(ngModel)]="patient_id" (change)="changePatient()">
          <option value="" [selected] = "patient_id == ''" disabled>Selecciona un Paciente</option>
          <option value="{{pat.id}}" *ngFor="let pat of patients" [selected] = "pat.id == patient_id">
            {{pat.first_name}} {{pat.last_name}} ({{pat.email}})
          </option>
        </select>
      </div>
      <div class="col-xl-2 pt-2" *ngIf="medications.length > 0 && !isView">
        <button class="btn btn-primary" type="button" [disabled]="disabled" (click)="saveRecipe()"><mat-icon>save</mat-icon> Guardar receta</button>
      </div>
      <div class="col-xl-2 pt-2" *ngIf="medications.length > 0 && isView">
        <button class="btn btn-warning" type="button" [disabled]="disabled" (click)="closeView()"><mat-icon>close</mat-icon> Cerrar receta</button>
      </div>
    </div>
    <div class="row border-bottom max-he" *ngFor="let m of medications; let i = index">
      <div class="col-sm-6 col-md-4">
        <div class="form-group">
          Medicamento
          <textarea name="" id="" cols="20" rows="3" class="form-control" [(ngModel)]="m.name"
            placeholder="Nombre y descripción del medicamento que se va recetar al paciente" [disabled]="disabled"></textarea>
        </div>
      </div>
      <div class="col-sm-6 col-md-4">
        <div class="form-group">
          Dosis
          <textarea name="" id="" cols="20" rows="3" class="form-control" [disabled]="disabled" [(ngModel)]="m.quantity"
            placeholder="La frecuencia y especificaciones de la toma del medicamento"></textarea>
        </div>
      </div>
      <div class="col-sm-6 col-md-2 pt-4">
        <div class="form-group text-center" *ngIf="!isView">
          <button class="btn btn-danger" (click)="deleteMedication(i)"><mat-icon>delete</mat-icon> Borrar medicamento</button>
        </div>
      </div>
    </div>
    <div class="row row justify-content-around mt-2">
      <div class="col-xl-3" *ngIf="!isView">
        <button class="btn btn-success" type="button" [disabled]="patient_id == ''" (click)="addMedication()">
          <mat-icon>add</mat-icon> Agregar medicamento
        </button>
      </div>
    </div>
  </section>
  <section class="container-fluid max-he mt-2" *ngIf="recipes.length > 0; else noRecipes">
    <h3>Recetas</h3>
    <div class="row max-he">
      <div class="col p-2 border rounded-sm max-he">
        <div class="row">
          <div class="col-4 text-center font-weight-bold">Paciente</div>
          <div class="col-4 text-center font-weight-bold">Cantidad de medicamentos</div>
          <div class="col-2 text-center font-weight-bold">Fecha</div>
          <div class="col-6 col-md-2 text-center font-weight-bold">Acciones</div>
        </div>
        <div [id]="'rec'+r.id" class="chat-card border-bottom p-2" *ngFor="let r of recipes">
          <div class="row">
            <div class="col-4">{{r.patient_id.first_name || ''}} {{r.patient_id.last_name || ''}}</div>
            <div class="col-4 text-center">{{r.medications.length || '1'}}</div>
            <div class="col-2 text-center">{{r.created_at || '' | date:'y-MM-d h:mm a'}}</div>
            <div class="col-2">
              <div class="col-12 text-center row justify-content-around p-0 m-0">
                <button class="btn btn-outline-primary col-xl-3 col-12" title="Detalle receta" (click)="viewRecipe(r)">
                  <mat-icon>search</mat-icon>
                </button>
                <button class="btn btn-outline-danger col-xl-3 col-12" title="Borrar receta" (click)="deleteRecipe(r.folio)">
                  <mat-icon>delete</mat-icon>
                </button>
                <button class="btn btn-outline-info col-xl-3 col-12" title="Ver receta" (click)="generatePDF(r)">
                  <mat-icon>attach_file</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <ng-template #noRecipes>
    <h3 class="text-center text-secondary"> No haz realizado recetas </h3>
  </ng-template>
</section>
<div class="loading" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
