<h2 mat-dialog-title class="text-center border bg-main text-white p-3"> Reagendar Cita </h2>
<section class="container-fluid">
    <div class="row col-12 mt-3">
        <div class="col-auto">
            <img [src]="doctor.photo_media_id ? doctor.photo_media_id.url : './assets/img/profile.jpg'" class="rounded-circle avatar" alt="avatar">
        </div>
        <div class="col-12">
            <h5> <b> {{doctor.first_name}} {{doctor.last_name}} </b> </h5>
        </div>
    </div>
    <div class="col-12 row align-items-center">
        <div class="col-12 p-4">
            <mat-divider></mat-divider>
            <div class="row">
                <!-- Dates select -->
                <div class="col-12">
                    <h5 class="text-secondary mt-3"> Nueva fecha y hora de la consulta </h5>
                    <div class="info-block shadow-sm row m-0">
                        <div class="col-7" (click)="picker.open()">
                            <button mat-button class="txt-main">
                                <mat-icon>calendar_today_outline</mat-icon>
                            </button>
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker" (dateChange)="getDate($event.value)" disabled
                                    [min]="minDate" placeholder="Seleciona una fecha">
                                <mat-datepicker disabled=false #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-5 center-flex">
                            <p class="m-0 mr-2 txt-main">
                                <mat-icon>access_time</mat-icon>
                            </p>
                            <mat-form-field>
                                <mat-label>{{timeLabel}}</mat-label>
                                <mat-select (selectionChange)="getFee()" [(ngModel)]="doctorTime">
                                    <ng-container *ngFor="let t of doctorTimeSlots">
                                        <mat-option  *ngIf="t.slot_type == 'availability'" [value]="t.start">
                                            {{t.start}}hrs
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<div mat-dialog-actions>
    <button mat-button mat-dialog-close > Cancelar </button>
    <button mat-button [disabled]="!isHourSelected" (click)="newBooking()"> Reagendar </button>
  </div>
