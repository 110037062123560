<h1 mat-dialog-title> Fuera de oficina </h1>
<mat-divider></mat-divider>
<section mat-dialog-content class="mt-4" >
    <div class="row m-0" *ngIf="addNew" >
        <!-- nombre -->
        <mat-form-field class="col-4">
          <span>Motivo</span>
            <input matInput [(ngModel)]="name" >
          </mat-form-field>
        <!-- Inicio -->
        <mat-form-field class="col-auto">
            <span>Inicio</span>
            <mat-select [(ngModel)]="inicio" >
              <mat-option *ngFor="let h of hoursI" [value]="h">
                {{h}} : 00 hrs
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- fin -->
          <mat-form-field class="col-auto">
            <span>Fin</span>
            <mat-select [(ngModel)]="fin"  >
              <mat-option *ngFor="let h of hoursF" [value]="h">
                {{h}} : 00 hrs
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- Día -->
          <mat-form-field>
            <span>Día</span>
            <input [(ngModel)]="dia" matInput disabled [matDatepicker]="picker" >
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #picker></mat-datepicker>
          </mat-form-field>

          <button class="col-auto text-primary" mat-button (click)="saveDay()" > <mat-icon> save </mat-icon> </button>
    </div>
  <button style="margin-top: 2rem;" mat-button (click)="addNew = !addNew" > {{addNew ? 'Cancelar' : 'Agregar día' }} </button>

</section>
<!--<table>
  <tr><th>Nombre</th><th>Hora Inicial</th><th>Hora Final</th><th>Día</th><th>Acciones</th></tr>
</table>-->
<mat-table [dataSource]="outsOfOffice">
<!-- Descripción Definition -->
<ng-container matColumnDef="description">
  <mat-header-cell *matHeaderCellDef> Descripción </mat-header-cell>
  <mat-cell *matCellDef="let row"> {{row.description}} </mat-cell>
</ng-container>

<!-- Inicio Definition -->
<ng-container matColumnDef="start_at">
  <mat-header-cell *matHeaderCellDef> Inicio (Año/Mes/Dia - Hora:Mins) </mat-header-cell>
  <mat-cell *matCellDef="let row"> {{row.start_at | date:'y/MM/dd - hh:mm a'}} </mat-cell>
</ng-container>

<!-- Fin Definition -->
<ng-container matColumnDef="end_at">
  <mat-header-cell *matHeaderCellDef> Fin (Año/Mes/Dia - Hora:Mins) </mat-header-cell>
  <mat-cell *matCellDef="let row"> {{row.end_at | date:'y/MM/dd - hh:mm a'}} </mat-cell>
</ng-container>

<!-- Acción Definition -->
<ng-container matColumnDef="delete">
  <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
  <mat-cell *matCellDef="let row" class="text-center">
    <button type="button" mat-stroked-button color="accent" title="Borrar horario" (click)="deleteOutOfOffice(row)" aria-label="Borrar horario">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-cell>
</ng-container>

<!-- Header and Row Declarations -->
<mat-header-row *matHeaderRowDef="['description', 'start_at', 'end_at', 'delete']"></mat-header-row>
<mat-row *matRowDef="let row; columns: ['description', 'start_at', 'end_at', 'delete']"></mat-row>
</mat-table>
