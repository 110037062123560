import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { User } from 'src/app/models/user.model';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit {

  hide = true;
  loading = false;

  uiSubs: Subscription = new Subscription();

  email = "";
  password = "";

  constructor( private authService:AuthService, private store: Store<AppState>, private router: Router ) { }

  ngOnInit() {
    this.store.select('user').subscribe( (data) => {
      if(data){
        this.router.navigate(['panel']);
      }
    } )

    this.uiSubs = this.store.select('ui').subscribe( ui=>{
      this.loading = ui.isLoading;
    } )
  }

  login() {

    let user = {"email": (this.email),"password": (this.password), "role": "doctor"};

    let encryptUser = {data: this.authService.encrypt(user,"public")};

    this.authService.login(user);
  }

}
