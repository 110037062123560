/* src/app/settings/settings.component.scss */
.avatar {
  width: 150px;
}
section {
  min-height: 93vh;
}
.s-box {
  height: 100%;
  justify-content: space-around;
}
.info-container {
  align-items: center;
  flex-flow: column;
  overflow: hidden;
}
.active-link {
  border: 1px solid gray;
}
/*# sourceMappingURL=settings.component.css.map */
