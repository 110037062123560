<nav class="navbar navbar-light bg-light">
    <a class="navbar-brand" [routerLink]="['/landing']" >
        <img src="./assets/logo.svg" width="30" height="30" alt="logo">
        <span> MindHelp </span>
    </a>
</nav>
<section class="container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
    <h3 class="txt-main" style="margin-top: 30vh;"> Recuperar Contraseña </h3>

    <div class="container" style="display: flex; align-items: center; justify-content: center; flex-direction: column;" *ngIf="!rePassSucc">
        <div class="row" style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
            <p class="col-12"> Ingrese su correo electronico para mandar la solicitud </p>
            <mat-form-field class="col-12">
              <label style="font-weight: 700;">Email</label>
                <input matInput [(ngModel)]="email" type="email"  >
            </mat-form-field>
        </div>
        <button class="btn btn-primary" style="margin-top: 10px;" [disabled]="loading" (click)="rePass()">
            <span *ngIf="!loading"> Enviar </span>
            <span *ngIf="loading"> Cargando ... </span>
        </button>
    </div>

    <div class="container" *ngIf="rePassSucc">
      <h2 class="text-secondary"> Se enviara un correo para que pueda restablecer su contraseña </h2>
    </div>

</section>
