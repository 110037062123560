import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { OnesignalService } from '../services/onesignal.service';

@Component({
    selector: 'app-panel',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.scss'],
    standalone: false
})
export class PanelComponent implements OnInit, OnDestroy {


  openMenu = false;
  titleNav = "";
  titleNavSubscription: Subscription = new Subscription();
  isEspecialista:boolean = true;

  constructor( private store: Store<AppState> ,  private osservice:OnesignalService, public auth: AuthService) {

   }

  ngOnInit() {
    this.titleNavSubscription = this.store.select('ui').subscribe(data =>{
      this.titleNav = data.title;
    })
    this.osservice.init();
    setTimeout(() => {this.openMenu = true;},3000)
  }

  ngOnDestroy(){
    this.titleNavSubscription.unsubscribe();
  }

  logout(){
    this.auth.logout()
  }

}
