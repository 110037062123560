/* src/app/landing/landing.component.scss */
.banner {
  width: 100%;
  height: 100vh;
  background-image: url("./media/banner.png");
  background-size: cover;
  background-position: center;
}
.navbar-nav {
  width: 100%;
  justify-content: flex-end;
}
nav {
  position: absolute;
  width: 100%;
}
.info-banner {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
}
.green-btm {
  border-bottom: 8px solid #24AB73;
}
.btn-landing {
  background-color: #24AB73;
  border-radius: 54px;
  width: 160px;
  height: 35px;
  text-align: center;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-bigger {
  transform: scale(1.3);
  margin: 0 0 0 25px;
}
.title-landing {
  font-size: 4rem;
}
.info-txt {
  font-size: 1.5rem;
}
.banner2 {
  background-color: #E7F9F5;
  width: 100%;
  min-height: 165px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}
.mini-card {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mini-card .img-mini {
  width: 80%;
}
.mini-card .mini-text {
  width: 50%;
  text-align: center;
}
.phone1 {
  margin-top: -70px;
}
.phone2 {
  margin-top: 95px;
}
footer {
  width: 100%;
  height: 180px;
  margin-top: -180px;
  background-image: url("./media/footer.png");
}
/*# sourceMappingURL=landing.component.css.map */
