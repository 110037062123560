/* src/app/horarios/horarios.component.scss */
.btn-container {
  position: fixed;
  bottom: 10px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 9999;
}
.btn-container button {
  width: 80%;
}
.add-btn {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 999;
}
.home-btn {
  position: fixed;
  top: 2px;
  right: 15px;
  z-index: 9999;
}
/*# sourceMappingURL=horarios.component.css.map */
