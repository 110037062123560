<div class="center-flex img-edit">
    <input id="fileInpt" accept="image/*" (change)="getFile($event)" class="d-none" type="file">
    <button mat-fab class="edit-btn text-white" (click)="clickFile('fileInpt')">
        <mat-icon>edit</mat-icon>
    </button>
    <div class="img-container">
        <img width="100%" id="imgTag" src="https://via.placeholder.com/300.png/09f/fff" alt="profile-img">
    </div>
</div>
<label for="" style="width: 100%; text-align: center; font-weight: bold;">Tamaño maximo del archivo 1MB</label>

<form [formGroup]="registerFG" (ngSubmit)="saveForm()">
    <div class="row justify-content-center mt-5">
        <div class="col-xl-6">
            <mat-form-field class="full-width">
              <label style="font-weight: 700; margin-bottom: -10px;">Nombre</label>
              <input matInput formControlName="first_name" required >
            </mat-form-field>
        </div>

        <div class="col-xl-6">
            <mat-form-field class="full-width">
              <label  style="font-weight: 700;margin-bottom: -10px;">Apellido</label>
              <input matInput formControlName="last_name" required>
            </mat-form-field>
        </div>

        <div class="col-xl-6 col-12">
            <mat-form-field class="full-width">
              <label  style="font-weight: 700;margin-bottom: -10px;">Email</label>
              <input matInput type="email" formControlName="email">
            </mat-form-field>
        </div>

        <div class="col-xl-6 col-12">
            <mat-form-field class="full-width">
              <label  style="font-weight: 700;margin-bottom: -10px;">Número Celular</label>
              <input matInput type="phone" formControlName="phone_number" required>
            </mat-form-field>
        </div>

        <div class="col-xl-6 col-12">
            <mat-form-field class="full-width">
                <label  style="font-weight: 700;margin-bottom: -10px;">Pais</label>
                <mat-select formControlName="country" required>
                    <mat-option value="MX">
                        México
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-xl-6 col-12">
            <mat-form-field class="full-width">
              <label  style="font-weight: 700;margin-bottom: -10px;">Fecha de nacimiento</label>
                <input matInput type="text" formControlName="date_of_birth">
            </mat-form-field>
        </div>

        <div class="col-xl-6 col-12">
            <mat-form-field class="full-width">
                <label style="font-weight: 700;margin-bottom: -10px;">Genero</label>
                <mat-select formControlName="gender" required>
                    <mat-option value="Male">
                        Masculino
                    </mat-option>
                    <mat-option value="Female">
                        Femenino
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-xl-6 col-12">
            <mat-form-field>
                <label  style="font-weight: 700;margin-bottom: -10px;">Trabajas desde</label>
                <input matInput [matDatepicker]="picker" formControlName="working_since" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker [disabled]="false" #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <mat-form-field class="col-12">
            <label  style="font-weight: 700;margin-bottom: -10px;">Especialidades</label>
            <mat-select formControlName="speciality" multiple>
                <mat-optgroup *ngFor="let group of espec" [label]="group.name" [disabled]="group.disabled">
                    <mat-option *ngFor="let s of group.services" [value]="s.id">{{s.name}}</mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>

        <div class=" col-12">
            <mat-form-field class="full-width">
                <label  style="font-weight: 700;margin-bottom: -10px;">Time Zone</label>
                <mat-select formControlName="time_zone" required>
                    <mat-option *ngFor="let tz of timeZones" [value]="tz">
                        {{tz}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-12">
            <mat-form-field class="full-width">
              <label style="font-weight: 700;margin-bottom: -10px;">Descripción</label>
                <textarea matInput formControlName="description" required cols="30"
                    rows="3"></textarea>
            </mat-form-field>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-6 text-center">
              Dibuja tu firma:
              <canvas #sigPad width="300" height="150" style="border:dashed 1px solid; background-color: #CCC;" (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)"></canvas>
            </div>
            <div class="col-6 text-center">
              Visualiza tu firma al guardarla:
              <img [src]="img" />
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <button (click)="limpiar()" type="button" class="btn btn-secondary m-2">Limpiar</button>
              <button (click)="guardar()" type="button" class="btn btn-success m-2">Guardar</button>
            </div>
          </div>
        </div>

        <div class="col-12 m-4">
          <div class="row">
            <div class="col-6" *ngIf="profile?.resume_media_id">
              <label  style="font-weight: 700;margin-bottom: -10px;"> Curriculum </label>&nbsp;&nbsp;<a  href="{{profile?.resume_media_id?.url}}" target="_blank">Abrir</a>
            </div>
            <div class="col-6">
              <input type="file" accept="application/pdf" (change)="getFileCv($event)">
            </div>
          </div>
        </div>

        <!-- Submit Button -->
        <div class="col-xl-7 col-12">
            <button [disabled]="registerFG.invalid" type="submit"
                class="full-width bg-main text-white btn"><!--(click)="saveForm()"-->
                Guardar
            </button>
        </div>
    </div>
</form>
