/* src/app/panel/panel.component.scss */
.content-app {
  margin-top: 65px;
}
.top-space {
  margin-top: 0px;
  text-align: center;
}
img {
  height: 52px;
  margin-top: 5px;
  margin-bottom: 5px;
}
/*# sourceMappingURL=panel.component.css.map */
