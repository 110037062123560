import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    standalone: false
})
export class ChangePasswordComponent implements OnInit {

  public registerFG: UntypedFormGroup;


  constructor( private service: AuthService,private _formBuilder: UntypedFormBuilder ) { }

  ngOnInit() {

    this.registerFG = this._formBuilder.group({
      password: ['', Validators.required],
      repassword: ['', Validators.required],
      
    });
  }

  saveForm() {

      let user = {
        "old_password": this.registerFG.value.password,
        "new_password": this.registerFG.value.repassword,
      }
  
      console.log(user)

      let encUser = {data: this.service.encrypt(user,"private")}

  }

}
