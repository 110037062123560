<section class="video-container" >
  <div *ngIf="session">
    <app-publisher [session]="session"></app-publisher>
    <app-subscriber class="doctor-camera" *ngFor="let stream of streams" [stream]="stream" [session]="session"></app-subscriber>
    <h1 *ngIf="streams.length == 0" class="text-white"> Esperando al paciente ... </h1>
  </div>
</section>

<section class="buttons-container">
  <button class="call-button ml-3" (click)="videoSettings('video')" >
    <mat-icon class="text-white" *ngIf="!this.video.video">videocam_off</mat-icon>
    <mat-icon class="text-white" *ngIf="this.video.video" >videocam</mat-icon>
  </button>
  <button class="call-button ml-3" (click)="videoSettings('mic')" >
    <mat-icon class="text-white" *ngIf="!this.video.audio" >mic_off</mat-icon>
    <mat-icon class="text-white" *ngIf="this.video.audio" >mic</mat-icon>
  </button>
  <button class="call-button ml-3 bg-danger" (click)="endCall()" > <mat-icon class="text-white" >phone_disabled</mat-icon> </button>
</section>
