<form [formGroup]="registerFG" (ngSubmit)="register()">
    <div class="row justify-content-center mt-5">
        <div class="col-xl-12">
            <mat-form-field class="full-width">
                <span>Nombre</span>
                <input matInput formControlName="name" required >
            </mat-form-field>
        </div>
        <div class="col-xl-6">
            <mat-form-field class="full-width" style="margin-top: 2rem;">
                <span>Celular</span>
                <input matInput type="phone" formControlName="phone" required >
            </mat-form-field>
        </div>
        <div class="col-xl-6">
            <mat-form-field class="full-width" style="margin-top: 2rem;">
                <span>Email</span>
                <input matInput type="email" formControlName="email" required>
            </mat-form-field>
        </div>
        <div class=" col-12">
            <mat-form-field class="full-width" style="margin-top: 2rem;">
                <span>Asunto</span>
                <textarea matInput type="email" formControlName="description"  required cols="30"
                    rows="10"></textarea>
            </mat-form-field>
        </div>

        <!-- Submit Button -->
        <div class="col-xl-7 col-12"style="margin-top: 2rem;">
            <button [disabled]="!registerFG.valid" type="submit" class="full-width bg-main text-white btn">
                Enviar
            </button>
        </div>
    </div>
</form>