<section class="container-fluid pt-3 max-he over-h">
  <section class="container-fluid max-he">
    <h3>{{title}}</h3>
    <div class="row justify-content-around">
      <div class="col-xl-6">
        Paciente
        <select name="" id="" class="form-control" [disabled]="disabled" [(ngModel)]="patient_id" (change)="getData()">
          <option value="" disabled>Selecciona un Paciente</option>
          <option value="{{pat.id}}" *ngFor="let pat of patients" [selected] = "pat.id == patient_id">
            {{pat.first_name}} {{pat.last_name}} ({{pat.email}})
          </option>
        </select>
      </div>
      <div class="col-xl-2 pt-2" *ngIf="diagnostics.length > 0 && !isView">
        <button class="btn btn-primary" type="button" [disabled]="disabled" (click)="save()"><mat-icon>save</mat-icon> Guardar Diagnóstico</button>
      </div>
      <div class="col-xl-2 pt-2" *ngIf="diagnostics.length > 0 && isView">
        <button class="btn btn-warning" type="button" [disabled]="disabled" (click)="closeView()"><mat-icon>close</mat-icon> Cerrar Diagnóstico</button>
      </div>
    </div>
    <!--<div *ngIf="patient_id != ''">-->
      <div class="row border-bottom max-he" *ngFor="let m of diagnostics; let i = index"><!--border-bottom max-he-->
        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            Diagnóstico
            <textarea cols="20" rows="3" class="form-control" [(ngModel)]="m.description"
            placeholder="Conclusiones de la consulta" [disabled]="disabled"></textarea>
          </div>
        </div>
        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            Recomendación
            <textarea cols="20" rows="3" class="form-control" [disabled]="disabled" [(ngModel)]="m.recomendation"
            placeholder="Recomendaciones para el paciente"></textarea>
          </div>
        </div>
      <!--<div class="col-sm-6 col-md-2">
        <div class="form-group">

        </div>
      </div>-->
        <!--<div class="col-sm-6 col-md-4 pt-2">
          <div class="form-group text-center" *ngIf="!isView">
            <button class="btn btn-danger" (click)="deleteDiagnostic(i)"><mat-icon>delete</mat-icon> Borrar diagnóstico</button>
          </div>
        </div>-->
      </div>
    <!--</div>-->
    <div class="row justify-content-md-center mt-2">
      <div class="col-2" *ngIf="!isView && !adding">
        <button class="btn btn-success" type="button" [disabled]="patient_id == ''" (click)="addDiagnostic()">
          <mat-icon>add</mat-icon> Agregar diagnóstico
        </button>
      </div>
    </div>
  </section>

  <section class="container-fluid max-he mt-2" *ngIf="data.length > 0; else noRecipes">
    <h3>Diagnósticos</h3>
    <div class="row max-he">
      <div class="col p-2 border rounded-sm max-he">
        <div class="row">
          <div class="col-2 text-center font-weight-bold">Paciente</div>
          <div class="col-3 text-center font-weight-bold">Diagnóstico</div>
          <div class="col-3 text-center font-weight-bold">Recomendación</div>
          <div class="col-2 text-center font-weight-bold">Fecha</div>
          <div class="col-2 col-md-2 text-center font-weight-bold">Acciones</div>
        </div>
        <div [id]="'rec'+r.id" class="chat-card border-bottom p-2" *ngFor="let r of data">
          <div class="row">
            <div class="col-2">{{r.patient_user_id.first_name || ''}} {{r.patient_user_id.last_name || ''}}</div>
            <div class="col-3 text-center">{{r.description || ''}}</div>
            <div class="col-3 text-center">{{r.recomendation || '1'}}</div>
            <div class="col-2 text-center">{{r.date_at || '' | date:'y-MM-d h:mm a'}}</div>
            <div class="col-2 col-md-2">
              <div class="col-12 text-center row justify-content-around p-0 m-0">
                <button class="btn btn-outline-primary col-xl-3 col-12" title="Detalle diagnóstico" (click)="viewRecipe(r)">
                  <mat-icon>search</mat-icon>
                </button>
                <button class="btn btn-outline-danger col-xl-3 col-12" title="Borrar diagnóstico" (click)="delete(r.id)">
                  <mat-icon>delete</mat-icon>
                </button>
                <button *ngIf="r.file" class="btn btn-outline-info col-xl-3 col-12" title="Ver archivo" (click)="generatePDF(r)">
                  <mat-icon>attach_file</mat-icon>
                </button>
                <button class="btn btn-outline-warning col-xl-3 col-12" title="Ver archivo" (click)="generateRecipe()">
                  <mat-icon>event_note</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <ng-template #noRecipes>
    <h3 class="text-center text-secondary"> No haz realizado diagnóstico </h3>
  </ng-template>
</section>

<div class="loading" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
