import { Component, OnInit, ChangeDetectorRef, OnDestroy, ComponentFactoryResolver } from '@angular/core';
import * as OT from '@opentok/client';
import { OpentokService } from 'src/app/services/opentok.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Subscription } from 'rxjs';
import { VideoSettings } from 'src/app/redux/ui.reducer';
import { SetVideoSettings } from 'src/app/redux/ui.actions';
import { ActivatedRoute } from '@angular/router';
import { ReviewComponent } from '../review/review.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';


@Component({
    selector: 'app-video-chat',
    templateUrl: './video-chat.component.html',
    styleUrls: ['./video-chat.component.scss'],
    standalone: false
})
export class VideoChatComponent implements OnInit, OnDestroy {
  title = 'Angular Basic Video Chat';
  session: OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;
  uiSUbscription: Subscription = new Subscription();
  video:VideoSettings = {
    video: true,
    audio: true
  }
  dateId = "";
  constructor(
    private ref: ChangeDetectorRef,
    private opentokService: OpentokService,
    private store: Store<AppState>,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public service: AuthService) {
      this.changeDetectorRef = ref;
      this.dateId = (this.route.snapshot.paramMap.get("id"));
  }

  ngOnInit () {
    this.opentokService.initSession().then((session: OT.Session) => {
      this.session = session;
      this.session.on('streamCreated', (event) => {
        this.streams.push(event.stream);
        if(!this.changeDetectorRef['destroyed'])
          this.changeDetectorRef.detectChanges();
      });
      this.session.on('streamDestroyed', (event) => {
        const idx = this.streams.indexOf(event.stream);
        if (idx > -1) {
          this.streams.splice(idx, 1);
          if(!this.changeDetectorRef['destroyed'])
            this.changeDetectorRef.detectChanges();
        }
      });
    })
    .then(() => this.opentokService.connect())
    .catch((err) => {
      console.error(err);
      alert("Imposible conectarese. Asegurate que tienes actualizado el arhivo config.ts con tus detalles de OpenTok");
      //'Unable to connect. Make sure you have updated the config.ts file with your OpenTok details.'
    });

    this.uiSUbscription = this.store.select('ui').subscribe( d=>{
      console.log(d.video)
      this.video = d.video;
    } )

  }

  ngOnDestroy(){
    this.opentokService.disconnect();
    this.uiSUbscription.unsubscribe();
    this.streams = [];
  }

  videoSettings(type) {
    const newVideoSettings = { ...this.video };

    if (type === 'video') {
      newVideoSettings.video = !newVideoSettings.video;
    } else {
      newVideoSettings.audio = !newVideoSettings.audio;
    }

    this.store.dispatch(new SetVideoSettings({ ...newVideoSettings })); // Dispatch con la nueva copia
  }


  endCall(){
    /*this.service.getDate(this.dateId).subscribe((d:any)=>{
      let decData = this.service.decrypt(d.message,'private');
      this.dialog.open(ReviewComponent, {
        width:'500px',
        height: '500px',
        data:{ id:this.dateId }
      });
    });*/
    this.dialog.open(ReviewComponent, {
      width:'500px',
      height: '500px',
      data:{ id:this.dateId }
    });
  }
}
