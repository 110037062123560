import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { Subscription } from 'rxjs';
import { CleartChatHistory } from '../redux/chat-history.actions';
import { ChangeTitleNav, ActivateLoadingAction, DeactivateLoadingAction } from '../redux/ui.actions';
import { RequestPushModel } from '../models/onesignal.models';
import { OnesignalService } from '../services/onesignal.service';
import moment from 'moment';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
    standalone: false
})
export class ChatComponent implements OnInit, OnDestroy {

  chatList = [];
  chatHistory = [];
  chatInput = "";
  curentChatId = 0;
  currentChat: any = null;
  chatSubscribe: Subscription = new Subscription();
  userID = 0;
  chatInterval;
  // redux
  uiSub: Subscription = new Subscription();
  isLoading = false;

  constructor(
    private service: AuthService,
    private store: Store<AppState>,
    private osService: OnesignalService) {
    this.uiSub = store.select('ui').subscribe(d => {
      this.isLoading = d.isLoading;
    })
  }

  ngOnInit() {

    this.userID = this.service.user.id;

    this.chatSubscribe = this.store.select('chatHistory').subscribe(c => {
      this.chatHistory = c.data.reverse();
      console.log(this.chatHistory)
    })

    this.store.dispatch(new ChangeTitleNav("Chats"));

    this.getChats();

  }

  putImg() {
    console.log("enter")
    this.chatList.forEach((elem, index) => {
      let img = elem.patient_user_id.photo_media_id;
      if (typeof img === 'number') {
        this.service.getImgId(img).subscribe((d: any) => {
          let decData = JSON.parse(this.service.decrypt(d.message, 'public'));
          if(decData.data.items.length > 0)
            this.chatList[index].patient_user_id.photo_media_id = decData.data.items[0].url
          else
            this.chatList[index].patient_user_id.photo_media_id = this.service.sinImagen();
        },(res) => {
          this.chatList[index].patient_user_id.photo_media_id = this.service.sinImagen();
        });
      }else
        this.chatList[index].patient_user_id.photo_media_id = this.service.sinImagen();
    });
  }

  ngOnDestroy() {
    this.chatSubscribe.unsubscribe();
    this.uiSub.unsubscribe();
    this.store.dispatch(new CleartChatHistory());
    this.store.dispatch(new ChangeTitleNav(""));
    clearInterval(this.chatInterval);
  }

  getChats() {
    this.store.dispatch(new ActivateLoadingAction());

    this.service.getChats(this.service.user.id).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, 'private'));
      let data = decData.data.items;
      this.chatList = data;
      this.store.dispatch(new DeactivateLoadingAction());
      this.putImg();
    }, err => {
      this.store.dispatch(new DeactivateLoadingAction());
      console.log(err)
    })
  }

  getChatHistory(c) {
    clearInterval(this.chatInterval);
    this.curentChatId = c.id
    this.currentChat = c;

    this.service.getChatHistory(c.patient_user_id.id, c.id);

    this.chatInterval = setInterval(() => {
      this.service.getChatHistory(c.patient_user_id.id, c.id);
    }, 7000);
  }

  onSubmit() {

    let data = {
      message: this.chatInput
    }

    let encData = { message: this.service.encrypt(data,"private") };

    this.service.sendMessage(this.service.user.id, this.curentChatId, encData).subscribe((d: any) => {
      // this.store.dispatch(new GetChatHistory([...d.data.items]))
      this.getChatHistory(this.currentChat);
    }, err => {
      console.log(err)
    });

    this.chatInput = "";
  }

  getMessage() { }

}
