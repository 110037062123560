<nav class="navbar navbar-light bg-main">
    <a class="navbar-brand">
        <img src="./assets/img/logo-blanco.png" height="30" alt="">
    </a>
</nav>

<div class="login-box border shadow rounded mt-5 animated pulse">
    <div class="row border-bottom col-12 text-center p-3">
        <div class="col-12 text-center">
            <img class="logo" src="./assets/logo.png" width="90px" alt="logo">
        </div>
    </div>
    <form [formGroup]="registerFG" class="col-12" (ngSubmit)="register()">
        <div class="login-container">
            <div class="col-xl-6 mt-4">
                <mat-form-field class="full-width">
                    <input matInput formControlName="first_name" required placeholder="Nombre">
                </mat-form-field>
            </div>
            <div class="col-xl-6 mt-4">
                <mat-form-field class="full-width">
                    <input matInput formControlName="last_name" required placeholder="Apellido">
                </mat-form-field>
            </div>
            <div class="col-xl-6 col-12 mt-4">
                <mat-form-field class="full-width">
                    <input matInput type="email" formControlName="email" required placeholder="Email">
                </mat-form-field>
            </div>
            <div class="col-xl-6 col-12 mt-2">
                <mat-form-field class="full-width">
                    <input matInput formControlName="password" required placeholder="Password"
                    [type]="hide ? 'password' : 'text'">
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                </mat-form-field>
            </div>

            <div class="col-xl-6 col-12 mt-2">
                <mat-form-field class="full-width">
                    <input matInput type="phone" formControlName="phone_number" required placeholder="Numero Celular">
                </mat-form-field>
            </div>

            <div class="col-xl-6 col-12 mt-2">
                <mat-form-field class="full-width">
                    <mat-label>Pais</mat-label>
                    <mat-select formControlName="country" required>
                        <mat-option value="MX">
                            Mexico
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-6 mt-2 " (click)="pickerc.open()" >
                <button mat-button type="button" class="txt-main" >
                    <mat-icon>calendar_today_outline</mat-icon>
                </button>
                <mat-form-field>
                    <input matInput name="date"
                        [max]="maxDate"
                        [matDatepicker]="pickerc"
                        (dateChange)="dateNow($event.value)" disabled
                        placeholder="Fecha de nacimiento">
                    <mat-datepicker disabled=false startView="multi-year" #pickerc></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-xl-6 col-12 mt-3">
                <mat-form-field class="full-width">
                    <mat-label>Genero</mat-label>
                    <mat-select formControlName="gender" required>
                        <mat-option value="Male">
                            Masculino
                        </mat-option>
                        <mat-option value="Female">
                            Femenino
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- Submit Button -->
            <div class="col-12 mt-2">
                <button [disabled]="!registerFG.valid" type="submit" class="full-width bg-main text-white" mat-button>Registrate</button>
                <!-- <button [disabled]="!registerFG.valid" type="submit" class="full-width bg-main text-white" mat-button>Iniciar sesión</button> -->
                <p class="full-width text-center mt-2"> ¿Ya tienes cuenta?
                    <a class="txt-main" [routerLink]="['/login']"> Iniciar sesión </a>
                </p>
            </div>
            <!-- stores buttons -->
            <div class="col-12 mb-3 row justify-content-around">
                <a href="https://play.google.com/store/apps/details?id=com.mindhelp.pacient&hl=en" target="_blank">
                    <img src="./assets/img/Playstore.png" alt="playstore">
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.mindhelp.pacient&hl=en" target="_blank">
                    <img src="./assets/img/Appstore.png" alt="appstore">
                </a>
            </div>
        </div>
    </form>

</div>
