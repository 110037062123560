<mat-drawer-container class="full-scroll">
    <!-- Side nav -->
    <mat-drawer class="o-h" mode="side" [opened]="openMenu">
        <div class="top-space">
            <img src="../../assets/logo.png" alt="Mindhelp">
        </div>
        <mat-divider></mat-divider>
        <div class="m-2 text-center">
            <a [routerLink]="['home']" routerLinkActive="txt-main">
                <mat-icon>label_outline</mat-icon>
                <p>Inicio</p>
            </a>
        </div>
        <mat-divider></mat-divider>
        <div class="m-3 text-center">
            <a [routerLink]="['booking']" routerLinkActive="txt-main">
                <mat-icon>calendar_today_outline</mat-icon>
                <p>Citas</p>
            </a>
        </div>
        <mat-divider></mat-divider>
        <div class="m-2 text-center">
            <a [routerLink]="['chat']" routerLinkActive="txt-main">
                <mat-icon>chat_bubble_outline</mat-icon>
                <p>Chat</p>
            </a>
        </div>
        <mat-divider></mat-divider>
        <div class="m-2 text-center">
            <a [routerLink]="['horarios']" routerLinkActive="txt-main">
                <mat-icon>access_time</mat-icon>
                <p>Agenda</p>
            </a>
        </div>
        <mat-divider *ngIf="auth.isEspecialista"></mat-divider>
        <div class="m-2 text-center" *ngIf="auth.isEspecialista">
            <a [routerLink]="['recipes']" routerLinkActive="txt-main">
                <mat-icon>event_note</mat-icon>
                <p>Recetas</p>
            </a>
        </div>
        <mat-divider *ngIf="auth.isEspecialista"></mat-divider>
        <div class="m-2 text-center" *ngIf="auth.isEspecialista">
            <a [routerLink]="['files']" routerLinkActive="txt-main">
                <mat-icon>folder</mat-icon><!--assignment-->
                <p>Diagnósticos</p>
            </a>
        </div>
        <mat-divider></mat-divider>
        <div class="m-2 text-center">
            <a [routerLink]="['settings']" routerLinkActive="txt-main">
                <mat-icon>settings_applications_outline</mat-icon>
                <p>Ajustes</p>
            </a>
        </div>
        <mat-divider></mat-divider>
        <div class="m-2 text-center">
            <a class="cursor" (click)="logout()">
                <mat-icon>input</mat-icon>
                <p> Salir </p>
            </a>
        </div>
        <mat-divider></mat-divider>
    </mat-drawer>
    <mat-drawer-content>
        <!-- Navbar -->
        <mat-toolbar class="bg-main text-white fixed">
            <button mat-button (click)="openMenu=!openMenu">
                <mat-icon *ngIf="!openMenu">menu</mat-icon>
                <mat-icon *ngIf="openMenu">menu_open</mat-icon>
            </button>
            <h3> {{titleNav}} </h3>
        </mat-toolbar>
        <!-- Aplication -->
        <section class="content-app">
            <router-outlet></router-outlet>
        </section>
    </mat-drawer-content>
</mat-drawer-container>
