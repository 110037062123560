<h2 class="bg-main p-2 text-white"> Terminar Cita </h2>

<div class="row justify-content-center align-items-center">
    <h4 class="text secondary"> ¿Desea terminar la cita? </h4>
</div>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button mat-dialog-close (click)="send()">Confirmar</button>
    <button mat-button mat-dialog-close (click)="sendAndDiagnostic()">Confirmar y Diagnosticar</button>
</mat-dialog-actions>
