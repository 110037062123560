<div class="center-flex">
    <button mat-button (click)="refesh()" >
        <mat-icon> cached </mat-icon> Refrescar
    </button>
</div>
<div class="container-fluid">
    <div class="row">
        <ng-container *ngFor="let b of bookings">
            <div *ngIf="b.status != 'declined'" class="row col-xl-4 col-12 content-info my-4">
                <div class="col-12 row mx-auto border">
                    <div class="row col-12 mt-3">
                        <div class="col-auto">
                            <img [src]="b.patient.photo_media_id ? b.patient.photo_media_id.url : './assets/img/profile.jpg'" class="rounded-circle avatar" alt="avatar">
                        </div>
                        <div class="col text-right">
                            <span
                                [ngClass]="{'badge-danger': b.status == 'expired' || b.status == 'declined', 'badge-success': b.status != 'expired' }"
                                class="badge">{{ returnStatus(b.status) }}</span>
                        </div>
                        <div class="col-12" *ngIf="!b.extra_firstname">
                            <h5> <b> {{b.patient_user_id.first_name}} {{b.patient_user_id.last_name}} </b> </h5>
                            <p class="m-0 txt-main"> {{b.category_name}} </p>
                        </div>
                        <div class="col-12" *ngIf="b.extra_firstname">
                            <h5> <b> {{b.extra_firstname}} {{b.extra_lastname}} </b> </h5>
                            <p class="m-0 txt-main"> {{b.category_name}} </p>
                        </div>
                        <div class="col-12">
                          <div class="row">
                            <div class="col-md-6">
                              <h6>
                                <a href="mailto:{{b.patient_user_id.email}}" target="_blank">{{b.patient_user_id.email}}</a>
                              </h6>
                              <h6>
                                <a href="tel:{{b.patient_user_id.phone}}" target="_blank">{{b.patient_user_id.phone}}</a>
                                <a href="https://api.whatsapp.com/send?phone=+{{b.patient_user_id.country_code}}{{b.patient_user_id.phone}}&text=Hola" target="_blank"><mat-icon>perm_phone_msg</mat-icon></a>
                              </h6>
                            </div>
                            <div class="col-md-6" *ngIf="b?.file?.file_id">
                              <label>Expediente:</label>
                              <a href="{{b.file.file_id.url}}" target="_blank"><mat-icon style="font-size:36px;margin:20px">folder</mat-icon></a>
                            </div>
                          </div>

                        </div>

                    </div>
                    <div class="col-12 row align-items-center">
                        <div class="col-12 p-4">
                            <mat-divider></mat-divider>
                            <div class="row">
                                <!-- Dates select -->
                                <div class="col-12">
                                    <h5 class="text-secondary mt-3"> Fecha y hora de la consulta </h5>
                                    <div class="info-block shadow-sm row m-0">
                                        <div class="col-6 center-flex">
                                            <p class="m-0 mr-2 txt-main">
                                                <mat-icon>calendar_today_outline</mat-icon>
                                            </p>
                                            <span class="text-secondary"> {{b.start_at | date:'mediumDate' }} </span>
                                        </div>
                                        <div class="col-6 center-flex">
                                            <p class="m-0 mr-2 txt-main">
                                                <mat-icon>access_time</mat-icon>
                                            </p>
                                            <span class="text-secondary"> {{b.start_at | date:'shortTime'}} </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 text-center row justify-content-around p-0 m-0">
                                    <button
                                        class="btn btn-primary bg-main col-12 my-2 text-white"
                                        (click)="goSession(b)"
                                        [disabled]="cheackDates(b.start_at)"
                                        *ngIf="!showSession(b.start_at)">
                                        <mat-icon>videocam_outline</mat-icon> Iniciar Sesión
                                    </button>
                                    <button class="btn btn-danger col-12 my-2 text-white"
                                        (click)="closeSession(b)" *ngIf="showSession(b.start_at)">
                                        <mat-icon>videocam_off_outline</mat-icon> Terminar Sesión
                                    </button>
                                    <button class="btn btn-primary bg-main col-12 my-2 text-white" (click)="goChat(b)"
                                        [disabled]="cheackDates(b.start_at)">
                                        <mat-icon>chat_outline</mat-icon> chat
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <h1 *ngIf="bookings.length == 0" class="text-secondary text-center col-12"> No tienes citas para hoy </h1>
    </div>
</div>

<div class="loading" *ngIf="isLoading" >
    <mat-spinner></mat-spinner>
</div>
