/* src/app/settings/edit-profile/edit-profile.component.scss */
.img-edit {
  position: relative;
}
.img-container {
  width: 250px;
  height: 250px;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
}
.edit-btn {
  position: absolute;
  top: 10px;
  right: 35%;
  z-index: 999;
}
/*# sourceMappingURL=edit-profile.component.css.map */
