<button mat-fab class="text-white home-btn" (click)="openWork()">
    <mat-icon> event_busy </mat-icon>
</button>
<button mat-fab class="text-white add-btn" (click)="openDialog('new')">
    <mat-icon> add </mat-icon>
</button>

<section class="container-fluid">
    <div class="row text-center">
        <div class="col-md-4">
            <div class="btn-group">
                <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()">
                    Anterior
                </div>
                <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                    Hoy
                </div>
                <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()">
                    Siguiente
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):locale }}</h3>
        </div>
        <div class="col-md-4">
            <div class="btn-group">
                <div class="btn btn-primary" (click)="setView(CalendarView.Month)"
                    [class.active]="view === CalendarView.Month">
                    Mes
                </div>
                <div class="btn btn-primary" (click)="setView(CalendarView.Week)"
                    [class.active]="view === CalendarView.Week">
                    Semana
                </div>
                <div class="btn btn-primary" (click)="setView(CalendarView.Day)"
                    [class.active]="view === CalendarView.Day">
                    Día
                </div>
            </div>
        </div>
    </div>
    <br />
    <div [ngSwitch]="view">
        <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
            [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
            (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"
            [locale]="locale">
        </mwl-calendar-month-view>
        <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
            [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)" [locale]="locale">
        </mwl-calendar-week-view>
        <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
            [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)" [locale]="locale">
        </mwl-calendar-day-view>
    </div>
</section>

<div class="loading" *ngIf="isLoading" >
    <mat-spinner></mat-spinner>
</div>
