<div *ngIf="isDate" class="row col-12 content-info my-4">
    <div *ngIf="isLoading" class="col-12 row mx-auto">
        <div class="row col-12 mt-3">
            <div  class="col-auto">
                <img *ngIf="user.status == 'out_of_office'" [src]="user.patient_user_id.photo_media_id" class="rounded-circle avatar" alt="avatar">
                <img *ngIf="user.status != 'out_of_office'" [src]="user.patient_user_id.photo_media_id" class="rounded-circle avatar-out-of-office" alt="avatar">
            </div>
            <div class="col text-right">
                <span *ngIf="user.status != 'out_of_office'" class="badge badge-success">{{ returnStatus(user.status)}}</span>
                <span *ngIf="user.status == 'out_of_office'" class="badge badge-danger">{{ returnStatus(user.status)}}</span>
            </div>
            <div *ngIf="user.status == 'out_of_office'" class="col-12">
              <h4> <b> {{user.patient_user_id.first_name}} </b> </h4>
            </div>
            <div *ngIf="user.status != 'out_of_office'" class="col-12">
                <h5> <b> {{user.patient_user_id.first_name}} {{user.patient_user_id.last_name}} </b> </h5>
            </div>
        </div>
        <div class="col-12 row align-items-center">
            <div class="col-12 p-4">
                <mat-divider></mat-divider>
                <div *ngIf="user.status != 'out_of_office'" class="row">
                    <!-- Dates select -->
                    <div class="col-12">
                        <h5 class="text-secondary mt-3"> Fecha y hora de la consulta </h5>
                        <div class="info-block shadow-sm row m-0">
                            <div class="col-6 center-flex">
                                <p class="m-0 mr-2 txt-main">
                                    <mat-icon>calendar_today_outline</mat-icon>
                                </p>
                                <span class="text-secondary"> {{user.start_at | date:'mediumDate' }} </span>
                            </div>
                            <div class="col-6 center-flex">
                                <p class="m-0 mr-2 txt-main">
                                    <mat-icon>access_time</mat-icon>
                                </p>
                                <span class="text-secondary"> {{user.start_at | date:'shortTime'}} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="user.status == 'out_of_office'" class="row">
                  <!-- Dates select -->
                  <div class="col-12">
                      <h5 class="text-secondary mt-3"> Fecha y hora Inicio </h5>
                      <div class="info-block shadow-sm row m-0">
                          <div class="col-6 center-flex">
                              <p class="m-0 mr-2 txt-main">
                                  <mat-icon>calendar_today_outline</mat-icon>
                              </p>
                              <span class="text-secondary"> {{user.start_at | date:'mediumDate' }} </span>
                          </div>
                          <div class="col-6 center-flex">
                              <p class="m-0 mr-2 txt-main">
                                  <mat-icon>access_time</mat-icon>
                              </p>
                              <span class="text-secondary"> {{user.start_at | date:'shortTime'}} </span>
                          </div>
                      </div>
                  </div>
                  <div class="col-12">
                    <h5 class="text-secondary mt-3"> Fecha y hora Fin </h5>
                    <div class="info-block shadow-sm row m-0">
                        <div class="col-6 center-flex">
                            <p class="m-0 mr-2 txt-main">
                                <mat-icon>calendar_today_outline</mat-icon>
                            </p>
                            <span class="text-secondary"> {{user.end_at | date:'mediumDate' }} </span>
                        </div>
                        <div class="col-6 center-flex">
                          <p class="m-0 mr-2 txt-main">
                              <mat-icon>access_time</mat-icon>
                          </p>
                          <span class="text-secondary"> {{user.end_at | date:'shortTime'}} </span>
                      </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
    </div>
    <h1 *ngIf="!isLoading" class="text-secondary text-center col-12"> Cargando datos ... </h1>
</div>

<h1 *ngIf="!isDate" class="text-secondary text-center col-12"> No tienes citas para esta hora </h1>
