<!-- <h1 mat-dialog-title> Configuración del Horario </h1> -->
<h1 mat-dialog-title> Configura tu Horario </h1>
<mat-divider></mat-divider>
<section mat-dialog-content>
  <div class="container-fluid mt-3">
    <div class="row">
      <!-- Slost de dias -->
      <div class="col-12 row m-0 mb-2" *ngFor="let day of schedule.schedule; let i = index">
        <div class="col-8"> {{ dayName(day.day) }} </div>
        <!-- slots del tiempo -->
        <div class="col-12 row m-0 align-items-center justify-content-center" *ngFor="let slot of day.availability; let x = index" >
          <!-- Inicio -->
          <span style="opacity: 0.5;">Inicio</span>
          <mat-form-field class="col-auto">
            <mat-select [(ngModel)] = "slot.start_at"  >
              <mat-option *ngFor="let h of hoursI" [value]="h">
                {{h}} : {{slot.minutes}} hrs
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- fin -->
          <span style="opacity: 0.5;">Fin</span>
          <mat-form-field class="col-auto">
            <mat-select [(ngModel)] ="slot.end_at"  >
              <mat-option *ngFor="let h of hoursF" [value]="h">
                {{h}} : {{slot.minutes}} hrs
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- minutos -->
          <span style="opacity: 0.5;">Minutos</span>
          <mat-form-field class="col-auto">
            <mat-select [(ngModel)] ="slot.minutes" >
              <mat-option *ngFor="let h of minutos" [value]="h">
                {{h}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- delete -->
          <div class="col-auto">
            <button (click)="deleteSlot(i,x)" mat-button class="text-danger">
              <mat-icon> delete </mat-icon>
            </button>
          </div>
        </div>
        <!-- agregar nuevo slot -->
        <div class="col-12 row m-0 justify-content-center">
          <button mat-button class="txt-main" (click)="addSlot(i)">
            <mat-icon> add </mat-icon>
          </button>
        </div>
        <mat-divider class="col-12"></mat-divider>
      </div>
    </div>
  </div>
</section>
<mat-dialog-actions>
  <button mat-button mat-dialog-close> Salir </button>
  <button mat-button (click)="saveHours()">Guardar</button>
</mat-dialog-actions>
