/* src/app/bookin/video-chat/video-chat.component.scss */
.video-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: #111;
  z-index: 999;
}
.doctor-camera {
  position: absolute;
  width: 80%;
  height: 90vh;
  top: 0;
  right: 190px;
}
.buttons-container {
  position: absolute;
  width: 100%;
  bottom: 100px;
  z-index: 999;
  display: flex;
  justify-content: center;
}
/*# sourceMappingURL=video-chat.component.css.map */
