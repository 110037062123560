import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-out-office',
    templateUrl: './out-office.component.html',
    styleUrls: ['./out-office.component.scss'],
    standalone: false
})
export class OutOfficeComponent implements OnInit {

  addNew = false;
  hoursI = [0,1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  hoursF = [1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  name= "";
  inicio = 7;
  fin = 24;
  dia = new Date();

  slots = [];

  outsOfOffice = [];

  constructor(
    public dialogRef: MatDialogRef<OutOfficeComponent>,
    private service: AuthService,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { }

  ngOnInit() {
    this.service.getOutOffice().subscribe( (d:any) =>{
      let decData = JSON.parse(this.service.decrypt(d.message, 'private'));
      console.log(decData.data.items)
      this.outsOfOffice = decData.data.items;
    } );
  }

  saveDay() {

    let day = moment(this.dia).format("MM-DD-YYYY");
    let start = `${day} ${this.inicio}:00:00`;
    let end = `${day} ${this.fin}:00:00`;


    let data = {
      description: this.name,
      start_at: moment(start).utc().format('x'),
      end_at: moment(end).utc().format('x')
    }

    let encData = { message: this.service.encrypt(data,"private") };

    console.log(data);
    this.service.setOutOffice(encData).subscribe (d =>{
      console.log(d)
      Swal.fire({
        icon: 'success',
        title: '¡Listo!',
        text: "Tus horarios fuera de oficina ya han sido actualizados.",
      });
      this.dialogRef.close(false);
    },(res)=>{
      res = this.service.decrypt(res.error.message,'private')
    })
  }

  deleteOutOfOffice(row){
    Swal.fire({
      icon:'warning',
      title:'¿Esta seguro?',
      text:'Borrar este horario de fuera de oficina',
      showCancelButton:true,
      confirmButtonText:'Si',
      cancelButtonText:'No, salir.',
      reverseButtons:true
    }).then((res) =>{
      if(res.isConfirmed){
        this.service.deleteOutOffice(row.id).subscribe((d:any) => {
          let decData = JSON.parse(this.service.decrypt(d.message, 'private'));
          console.log(decData.data.items);
          Swal.close();
          this.service.getOutOffice().subscribe( (d:any) =>{
            let decData = JSON.parse(this.service.decrypt(d.message, 'private'));
            console.log(decData.data.items)
            this.outsOfOffice = decData.data.items;
          } );
        })
      }
    });
  }

}
