/* src/app/auth/login/login.component.scss */
.login-container {
  display: flex;
  flex-flow: column;
}
.login-box {
  width: 500px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .login-box {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}
/*# sourceMappingURL=login.component.css.map */
