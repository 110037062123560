import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-review',
    templateUrl: './review.component.html',
    styleUrls: ['./review.component.scss'],
    standalone: false
})
export class ReviewComponent implements OnInit {

  key:string;

  constructor(private service: AuthService, @Inject(MAT_DIALOG_DATA) public data, private router: Router) {
    //this.key= localStorage.getItem("ppk");
  }

  ngOnInit() {
  }

  send() {

    this.service.datesFinish(this.data.id).subscribe(d => {
      //d = JSON.parse(this.service.decrypt(d.message,'private'));
      console.log(d)
      this.router.navigate(['/panel/home']);

    });

  }

  sendAndDiagnostic() {

    this.service.datesFinish(this.data.id).subscribe((d:any) => {
      let decData = JSON.parse(this.service.decrypt(d.message,'private'));
      console.log(d)
      this.router.navigate(['/panel/recipes', decData.data.items[0].patient_user_id]);

    });

  }

}
