import { Component, OnInit, Inject } from '@angular/core';
import Swal from 'sweetalert2';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { isMonday } from 'date-fns';
import moment from 'moment';
import momentTZ from 'moment-timezone';


@Component({
    selector: 'app-hour-select',
    templateUrl: './hour-select.component.html',
    styleUrls: ['./hour-select.component.scss'],
    standalone: false
})
export class HourSelectComponent implements OnInit {

  hoursI = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  hoursF = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  minutos = [0, 30];

  GMT = moment().format('Z');

  day = new Date();

  time_zone;
  schedule = {
    schedule: [
      {
        day: "monday",
        availability: []
      },
      {
        day: "tuesday",
        availability: []
      },
      {
        day: "wednesday",
        availability: []
      },
      {
        day: "thursday",
        availability: []
      },
      {
        day: "friday",
        availability: []
      },
      {
        day: "saturday",
        availability: []
      },
      {
        day: "sunday",
        availability: []
      },
    ]
  }

  constructor(
    public dialogRef: MatDialogRef<HourSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private service: AuthService) { }

  ngOnInit() {
    var resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
    this.time_zone = this.service.user.time_zone ? this.service.user.time_zone : resolvedOptions.timeZone;
    console.log(this.GMT)

    this.service.getTimeDetails().subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, 'private'));
      if (decData.data.items.length > 0) {
        let day1 = [];
        let day2 = [];
        let day3 = [];
        let day4 = [];
        let day5 = [];
        let day6 = [];
        let day7 = [];

        // start
        decData.data.items.forEach((element, index) => {
          element.availability.forEach((elem) => {
            let offset = moment().utcOffset();
            let hours_offset = offset/60;

            let start = moment.utc(`01-01-2020 ${elem.start_at}:${elem.minutes == 0 ? '00' : '30'}:00`).format();
            let start_at = moment.utc(start).utcOffset(offset).format("HH");
            let startHour = moment.utc(start).format('HH');

            let end = moment.utc(`01-01-2020 ${elem.end_at}:${elem.minutes == 0 ? '00' : '30'}:00`).format();
            let end_at = moment.utc(end).utcOffset(offset).format("HH");

            // console.log(startHour)
            // console.log("Start: ", start_at, " End: ", end_at);

            //Cambio de dia si las horas pertenecen a dia anterior - GCG_17022023
            if((elem.start_at + hours_offset) < 0){//dia anterior
              if(elem.day == 'monday') elem.day = "sunday";
              else if(elem.day == 'tuesday') elem.day = "monday";
              else if(elem.day == 'wednesday') elem.day = "tuesday";
              else if(elem.day == 'thursday') elem.day = "wednesday";
              else if(elem.day == 'friday') elem.day = "thursday";
              else if(elem.day == 'saturday') elem.day = "friday";
              else if(elem.day == 'sunday') elem.day = "saturday";
            }

            switch(elem.day){
              case "monday":////0
                    var result = this.checkArray(day1, start_at, end_at);
                    if(result.length == 0)
                      day1.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                    else
                      day1 = result;
                  break;
                case "tuesday":////1
                    var result = this.checkArray(day2, start_at, end_at);
                    if(result.length == 0)
                      day2.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                    else
                      day2 = result;
                  break;
                case "wednesday":////2
                    var result = this.checkArray(day3, start_at, end_at);
                    if(result.length == 0)
                      day3.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                    else
                      day3 = result;
                  break;
                case "thursday":////3
                    var result = this.checkArray(day4, start_at, end_at);
                    if(result.length == 0)
                      day4.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                    else
                      day4 = result;
                  break;
                case "friday":////4
                    var result = this.checkArray(day5, start_at, end_at);
                    if(result.length == 0)
                      day5.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                    else
                      day5 = result;
                  break;
                case "saturday":////5
                    var result = this.checkArray(day6, start_at, end_at);
                    if(result.length == 0)
                      day6.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                    else
                      day6 = result;
                  break;
                case "sunday":////6
                    var result = this.checkArray(day7, start_at, end_at);
                    if(result.length == 0)
                      day7.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                    else
                      day7 = result;
                  break;
            }
            /*if (startHour == '00') {

              switch (elem.day){////(index) {
                case "sunday":////0
                  day7.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                  break;
                case "monday":////1
                  day1.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                  break;
                case "tuesday":////2
                  day2.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                  break;
                case "wednesday":////3
                  day3.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                  break;
                case "thursday":////4
                  day4.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                  break;
                case "friday":////5
                  day5.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                  break;
                case "saturday":////6
                  day6.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                  break;
              }
            } else {
              switch (index) {
                case "monday":////0
                  day1.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                  break;
                case "tuesday":////1
                  day2.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                  break;
                case "wednesday":////2
                  day3.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                  break;
                case "thursday":////3
                  day4.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                  break;
                case "friday":////4
                  day5.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                  break;
                case "saturday":////5
                  day6.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                  break;
                case "sunday":////6
                  day7.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
                  break;
              }
            }*/
          });

        });

        let schedule = {
          schedule: [
            { day: "monday", availability: day1 },
            { day: "tuesday", availability: day2 },
            { day: "wednesday", availability: day3 },
            { day: "thursday", availability: day4 },
            { day: "friday", availability: day5 },
            { day: "saturday", availability: day6 },
            { day: "sunday", availability: day7 },
          ]
        }

        console.log(schedule);
        this.schedule = schedule;

        /*schedule.schedule.forEach(sc => {
          sc.availability.forEach(av => {
            this.schedule.schedule[sc.day].push(av);
          });
        });*/
      }
    });
  }

  checkArray(day, start_at, end_at){
    var finded = false;
    for(let i = 0; i < day.length;i++){
      if(day[i].end_at == start_at){
        if(day[i].start_at > day[i].end_at)
          day[i].start_at = Number(start_at);
        else
          day[i].end_at = Number(end_at);
        if(day[i].start_at == day[i].end_at && day[i].end_at == 0) day[i].end_at = 24;
        finded = true; break;
      }else if(day[i].start_at == end_at){
        day[i].start_at = Number(start_at);
        finded = true;break;
      }
    }
    return finded ? day : [];
  }

  addSlot(day) {
    this.schedule.schedule[day].availability.push({ start_at: 7, end_at: 23, available: true, minutes: 0 });
  }

  deleteSlot(day, slot) {
    this.schedule.schedule[day].availability.splice(slot, 1);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  dayName(day) {

    switch (day) {
      case "monday":
        return "Lunes";
      case "tuesday":
        return "Martes";
      case "wednesday":
        return "Miercoles";
      case "thursday":
        return "Jueves";
      case "friday":
        return "Viernes";
      case "saturday":
        return "Sabado";
      case "sunday":
        return "Domingo";
    }

  }

  saveHours() {

    let day1 = [];
    let day2 = [];
    let day3 = [];
    let day4 = [];
    let day5 = [];
    let day6 = [];
    let day7 = [];

    this.schedule.schedule.forEach((element, index) => {
      // console.log(element)

      element.availability.forEach((elem) => {
        let start = moment(`01-01-2020 ${elem.start_at}:${elem.minutes == 0 ? '00' : '30'}:00${this.GMT}`).format('');
        ////let start = moment(`01-01-2020 ${elem.start_at}:${elem.minutes == 0 ? '00' : '30'}:00`).format('');
        let start_at = moment.utc(start).format('HH');
        let day_start_at = moment(start).format('dddd');
        let day_start_at_utc = moment.utc(start).format('dddd');
        ////let start_at = moment(start).format('HH');
        // console.log(start_at)

        let end = moment(`01-01-2020 ${elem.end_at}:${elem.minutes == 0 ? '00' : '30'}:00${this.GMT}`).format('');
        ////let end = moment(`01-01-2020 ${elem.end_at}:${elem.minutes == 0 ? '00' : '30'}:00`).format('');
        let end_at = moment.utc(end).format('HH');
        let day_end_at = moment(end).format('dddd');
        let day_end_at_utc = moment.utc(end).format('dddd');
        ////let end_at = moment(end).format('HH');
        end_at = end_at == '00' ? '24' : end_at;
        let dif = false;
        let sig = false;
        if(Number(start_at) > Number(end_at)) dif = true;
        else{
          if(day_start_at_utc != day_end_at_utc) dif=true;
          else{
            if(day_start_at != day_start_at_utc || day_end_at != day_end_at_utc){
              if(day_start_at == day_end_at) sig = true;
            }
          }
        }
        // console.log(end_at)

        switch (index) {
          case 0:
            if(dif){
              day1.push({ start_at: Number(start_at), end_at: 24, available: true, minutes: elem.minutes });
              if(end_at != '24')
                day2.push({ start_at: 0, end_at: Number(end_at), available: true, minutes: elem.minutes });
            }else{
              if(sig)
                day2.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes });
              else
                day1.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes });
            }
            break;
          case 1:
            if(dif){
              day2.push({ start_at: Number(start_at), end_at: 24, available: true, minutes: elem.minutes });
              if(end_at != '24')
                day3.push({ start_at: 0, end_at: Number(end_at), available: true, minutes: elem.minutes });
            }else
              if(sig)
                day3.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes });
              else
                day2.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes });
            break;
          case 2:
            if(dif){
              day3.push({ start_at: Number(start_at), end_at: 24, available: true, minutes: elem.minutes });
              if(end_at != '24')
                day4.push({ start_at: 0, end_at: Number(end_at), available: true, minutes: elem.minutes });
            }else
              if(sig)
                day4.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes });
              else
                day3.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes });
            break;
          case 3:
            if(dif){
              day4.push({ start_at: Number(start_at), end_at: 24, available: true, minutes: elem.minutes });
              if(end_at != '24')
                day5.push({ start_at: 0, end_at: Number(end_at), available: true, minutes: elem.minutes });
            }else
              if(sig)
                day5.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes });
              else
                day4.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes });
            break;
          case 4:
            if(dif){
              day5.push({ start_at: Number(start_at), end_at: 24, available: true, minutes: elem.minutes });
              if(end_at != '24')
                day6.push({ start_at: 0, end_at: Number(end_at), available: true, minutes: elem.minutes });
            }else
              if(sig)
                day6.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes });
              else
                day5.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes });
            break;
          case 5:
            if(dif){
              day6.push({ start_at: Number(start_at), end_at: 24, available: true, minutes: elem.minutes });
              if(end_at != '24')
                day7.push({ start_at: 0, end_at: Number(end_at), available: true, minutes: elem.minutes });
            }else
              if(sig)
                day7.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes });
              else
                day6.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes });
            break;
          case 6:
            if(dif){
              day7.push({ start_at: Number(start_at), end_at: 24, available: true, minutes: elem.minutes });
              if(end_at != '24')
                day1.push({ start_at: 0, end_at: Number(end_at), available: true, minutes: elem.minutes });
            }else
              if(sig)
                day1.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes });
              else
                day7.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes });
            break;
          }

        /*if ((Number(end_at) > 0) && (Number(end_at) < Number(start_at))) {

          switch (index) {
            case 0:
              day1.push({ start_at: Number(start_at), end_at: 24, available: true, minutes: elem.minutes })
              day2.push({ start_at: 0, end_at: Number(end_at), available: true, minutes: elem.minutes })
              break;
            case 1:
              day2.push({ start_at: Number(start_at), end_at: 24, available: true, minutes: elem.minutes })
              day3.push({ start_at: 0, end_at: Number(end_at), available: true, minutes: elem.minutes })
              break;
            case 2:
              day3.push({ start_at: Number(start_at), end_at: 24, available: true, minutes: elem.minutes })
              day4.push({ start_at: 0, end_at: Number(end_at), available: true, minutes: elem.minutes })
              break;
            case 3:
              day4.push({ start_at: Number(start_at), end_at: 24, available: true, minutes: elem.minutes })
              day5.push({ start_at: 0, end_at: Number(end_at), available: true, minutes: elem.minutes })
              break;
            case 4:
              day5.push({ start_at: Number(start_at), end_at: 24, available: true, minutes: elem.minutes })
              day6.push({ start_at: 0, end_at: Number(end_at), available: true, minutes: elem.minutes })
              break;
            case 5:
              day6.push({ start_at: Number(start_at), end_at: 24, available: true, minutes: elem.minutes })
              day7.push({ start_at: 0, end_at: Number(end_at), available: true, minutes: elem.minutes })
              break;
            case 6:
              day7.push({ start_at: Number(start_at), end_at: 24, available: true, minutes: elem.minutes })
              day1.push({ start_at: 0, end_at: Number(end_at), available: true, minutes: elem.minutes })
              break;
          }
        } else {
          switch (index) {
            case 0:
              day1.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
              break;
            case 1:
              day2.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
              break;
            case 2:
              day3.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
              break;
            case 3:
              day4.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
              break;
            case 4:
              day5.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
              break;
            case 5:
              day6.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
              break;
            case 6:
              day7.push({ start_at: Number(start_at), end_at: Number(end_at), available: true, minutes: elem.minutes })
              break;
          }
        }*/
      });

    });

    let schedule = {
      schedule: [
        { day: "monday", availability: day1 },
        { day: "tuesday", availability: day2 },
        { day: "wednesday", availability: day3 },
        { day: "thursday", availability: day4 },
        { day: "friday", availability: day5 },
        { day: "saturday", availability: day6 },
        { day: "sunday", availability: day7 },
      ]
    }

    console.log(schedule);

    // return;
    this.service.setDocHours(this.service.user.id, schedule).subscribe(d => {
      console.log(d)
      Swal.fire({
        icon: 'success',
        title: '¡Felicidades!',
        text: "Tus horarios disponibles se han publicado con éxito",
      });
      this.onNoClick();
    }, err => {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: err.error.error.errors[0].message,
      });
    })
  }

}
