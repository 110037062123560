/* src/app/bookin/bookin.component.scss */
.avatar {
  width: 100px;
  height: 100px;
}
.content-info {
  align-items: baseline;
}
.get-more {
  margin-bottom: 10px;
}
/*# sourceMappingURL=bookin.component.css.map */
