<mat-nav-list>
    <a mat-list-item (click)="response('Mi paciente y yo no nos pudimos conectar')">
      <span mat-line>Mi paciente y yo no nos pudimos conectar</span>
    </a>
  
    <a mat-list-item (click)="response('Mi paciente me pidió cancelar ')">
      <span mat-line>Mi paciente me pidió cancelar</span>
    </a>
  
    <a mat-list-item (click)="response('El paciente no se conecto')">
      <span mat-line>El paciente no se conecto</span>
    </a>
  
    <a mat-list-item (click)="response('No estoy disponible')">
      <span mat-line>No estoy disponible</span>
    </a>

    <a mat-list-item (click)="closeModal()">
        <span mat-line> <b> Cancelar </b></span>
      </a>
  </mat-nav-list>