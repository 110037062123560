<nav class="navbar navbar-light bg-main">
    <a class="navbar-brand" >
      <img src="./assets/img/logo-blanco.png"  height="30" alt="">
    </a>
  </nav>

<div class="login-box border shadow rounded mt-5 animated pulse">
    <div class="row border-bottom col-12 text-center p-3">
        <div class="col-12">
            <img class="logo" width="80px" src="./assets/logo.png" alt="logo">
          <!--  <label *ngIf="authService.ipServer == 'http://localhost:1337/'">Test</label>-->
        </div>
    </div>
    <form (ngSubmit)="login()" class="col-12" >
        <div class="login-container mt-5">
            <div class="col-12">
                <mat-form-field class="full-width">
                  <label style="font-weight: 700;">Correo</label>
                    <input [(ngModel)]="email" name="email" matInput >
                </mat-form-field>
            </div>
            <div class="col-12 mt-4">
                <mat-form-field class="full-width">
                  <label style="font-weight: 700;">Contraseña</label>
                    <input [(ngModel)]="password" matInput name="password" [type]="hide ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="col-12 mb-3 text-right mt-2">
                <a [routerLink]="['/recuperar']"  > Recuperar Contraseña </a>
            </div>
            <div class="col-12">
                <button type="submit" [disabled]="loading" class="full-width bg-main text-white" mat-button>
                    <span *ngIf="!loading"> Iniciar sesión </span>
                    <span *ngIf="loading" > Cargando ...</span>
                </button>
                <p class="full-width text-center mt-2" > ¿No tienes cuenta? <a class="txt-main" [routerLink]="['/register']" > Registrate </a> </p>
            </div>
            <!-- stores buttons -->
            <div class="col-12 mb-3 row justify-content-around">
                <a href="https://play.google.com/store/apps/details?id=com.mindhelp.pacient&hl=en" target="_blank" >
                    <img src="./assets/img/Playstore.png" alt="playstore">
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.mindhelp.pacient&hl=en" target="_blank" >
                    <img src="./assets/img/Appstore.png" alt="appstore">
                </a>
            </div>
        </div>
    </form>
</div>
