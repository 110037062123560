<div class="center-flex">
    <button mat-button (click)="refresh()" >
        <mat-icon> cached </mat-icon> Refrescar
    </button>
</div>
<section class="container-fluid">
    <mat-tab-group (selectedTabChange)="changeTab($event)">
      <mat-tab label="Por Confirmar">
        <div class="container-fluid">
            <div class="row">
                <div *ngFor="let b of bookingsToConfirm" class="row col-xl-4 col-12 content-info my-4">
                    <div class="col-12 row mx-auto border">
                        <div class="row col-12 mt-3">
                            <div class="col-auto">
                                <img [src]="b.patient.photo_media_id ? b.patient.photo_media_id.url : './assets/img/profile.jpg'"
                                    class="rounded-circle avatar" alt="avatar">
                            </div>
                            <div class="col text-right">
                                <span
                                    [ngClass]="{'badge-danger': b.status == 'expired' || b.status == 'declined' , 'badge-success': b.status != 'expired' }"
                                    class="badge">{{ 'Por Confirmar' }}</span>
                            </div>
                            <div class="col-12" *ngIf="!b.extra_firstname">
                                <h5> <b> {{b.patient_user_id.first_name}} {{b.patient_user_id.last_name}} </b> </h5>
                                <p class="m-0 txt-main"> {{b.category_name}} </p>
                            </div>
                            <div class="col-12" *ngIf="b.extra_firstname">
                                <h5> <b> {{b.extra_firstname}} {{b.extra_lastname}} </b> </h5>
                                <p class="m-0 txt-main"> {{b.category_name}} </p>
                            </div>
                            <div class="col-12">
                              <h6>
                                <a href="mailto:{{b.patient_user_id.email}}" target="_blank">{{b.patient_user_id.email}}</a>
                              </h6>
                              <h6>
                                <a href="tel:{{b.patient_user_id.phone}}" target="_blank">{{b.patient_user_id.phone}}</a>
                                <a href="https://api.whatsapp.com/send?phone=+{{b.patient_user_id.country_code}}{{b.patient_user_id.phone}}&text=Hola" target="_blank"><mat-icon>perm_phone_msg</mat-icon></a>
                              </h6>
                            </div>
                        </div>
                        <div class="col-12 row align-items-center">
                            <div class="col-12 p-4">
                                <mat-divider></mat-divider>
                                <div class="row">
                                    <!-- Dates select -->
                                    <div class="col-12">
                                        <h5 class="text-secondary mt-3"> Fecha y hora de la consulta </h5>
                                        <div class="info-block shadow-sm row m-0">
                                            <div class="col-6 center-flex">
                                                <p class="m-0 mr-2 txt-main">
                                                    <mat-icon>calendar_today_outline</mat-icon>
                                                </p>
                                                <span class="text-secondary"> {{b.start_at | date:'mediumDate' }}
                                                </span>
                                            </div>
                                            <div class="col-6 center-flex">
                                                <p class="m-0 mr-2 txt-main">
                                                    <mat-icon>access_time</mat-icon>
                                                </p>
                                                <span class="text-secondary"> {{b.start_at | date:'shortTime'}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="b.status == 'to_be_confirmed_by_therapist'"
                                        class="col-12 text-center row justify-content-around p-0 m-0">
                                        <button class="btn btn-primary bg-main col-xl-5 col-12 my-2 text-white"
                                            (click)="acceptBooking('accepted', b)"> Aceptar </button>
                                        <button class="btn btn-outline-danger col-xl-5 col-12 my-2"
                                            (click)="acceptBooking('declined', b)"> Rechazar </button>
                                        <!--<button class="btn btn-outline-success col-xl-4 col-12 my-2"
                                            (click)="acceptBooking('',b)"> Reagendar </button>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
        <!-- Next -->
        <mat-tab label="Agendadas">
            <div class="container-fluid">
                <div class="row">
                    <div *ngFor="let b of bookingsUpcoming" class="row col-xl-4 col-12 content-info my-4">
                        <div class="col-12 row mx-auto border">
                            <div class="row col-12 mt-3">
                                <div class="col-auto">
                                    <img [src]="b.patient.photo_media_id ? b.patient.photo_media_id.url : './assets/img/profile.jpg'"
                                        class="rounded-circle avatar" alt="avatar">
                                </div>
                                <div class="col text-right">
                                    <span
                                        [ngClass]="{'badge-danger': b.status == 'expired' || b.status == 'declined' , 'badge-success': b.status != 'expired' }"
                                        class="badge">{{'Agendada'}}</span>
                                </div>
                                <div class="col-12" *ngIf="!b.extra_firstname">
                                    <h5> <b> {{b.patient_user_id.first_name}} {{b.patient_user_id.last_name}} </b> </h5>
                                    <p class="m-0 txt-main"> {{b.category_name}} </p>
                                </div>
                                <div class="col-12" *ngIf="b.extra_firstname">
                                    <h5> <b> {{b.extra_firstname}} {{b.extra_lastname}} </b> </h5>
                                    <p class="m-0 txt-main"> {{b.category_name}} </p>
                                </div>
                                <div class="col-12">
                                  <h6>
                                    <a href="mailto:{{b.patient_user_id.email}}" target="_blank">{{b.patient_user_id.email}}</a>
                                  </h6>
                                  <h6>
                                    <a href="tel:{{b.patient_user_id.phone}}" target="_blank">{{b.patient_user_id.phone}}</a>
                                    <a href="https://api.whatsapp.com/send?phone=+{{b.patient_user_id.country_code}}{{b.patient_user_id.phone}}&text=Hola" target="_blank"><mat-icon>perm_phone_msg</mat-icon></a>
                                  </h6>
                                </div>
                            </div>
                            <div class="col-12 row align-items-center">
                                <div class="col-12 p-4">
                                    <mat-divider></mat-divider>
                                    <div class="row">
                                        <!-- Dates select -->
                                        <div class="col-12">
                                            <h5 class="text-secondary mt-3"> Fecha y hora de la consulta </h5>
                                            <div class="info-block shadow-sm row m-0">
                                                <div class="col-6 center-flex">
                                                    <p class="m-0 mr-2 txt-main">
                                                        <mat-icon>calendar_today_outline</mat-icon>
                                                    </p>
                                                    <span class="text-secondary"> {{b.start_at | date:'mediumDate' }}
                                                    </span>
                                                </div>
                                                <div class="col-6 center-flex">
                                                    <p class="m-0 mr-2 txt-main">
                                                        <mat-icon>access_time</mat-icon>
                                                    </p>
                                                    <span class="text-secondary"> {{b.start_at | date:'shortTime'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 text-center row justify-content-around p-0 m-0">
                                          <button class="btn btn-outline-success col-xl-4 col-12 my-2"
                                            (click)="reDateBooking(b)"> Reagendar </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <!-- Past -->
        <mat-tab label="Terminadas">
            <div class="container-fluid">
                <div class="row">
                    <div *ngFor="let b of bookingsPast" class="row col-xl-4 col-12 content-info my-4">
                        <div class="col-12 row mx-auto border">
                            <div class="row col-12 mt-3">
                                <div class="col-auto">
                                    <img [src]="b.patient.photo_media_id ? b.patient.photo_media_id.url : './assets/img/profile.jpg'"
                                        class="rounded-circle avatar" alt="avatar">
                                </div>
                                <div class="col-8" *ngIf="!b.extra_firstname">
                                    <h3> <b> {{b.patient_user_id.first_name}} {{b.patient_user_id.last_name}} </b> </h3>
                                    <p class="m-0 txt-main"> {{b.category_name || 'categoria'}} </p>
                                </div>
                                <div class="col-8" *ngIf="b.extra_firstname">
                                    <h3> <b> {{b.extra_firstname}} {{b.extra_lastname}} </b> </h3>
                                    <p class="m-0 txt-main"> {{b.category_name || 'categoria'}} </p>
                                </div>
                                <div class="col-auto">
                                    <span class="badge"
                                        [ngClass]="{'badge-danger': b.status == 'expired', 'badge-success': b.status != 'expired' }">{{'Terminada'}}</span>
                                </div>
                            </div>
                            <div class="col-12 row align-items-center">
                                <div class="col-12 p-4">
                                    <mat-divider></mat-divider>
                                    <div class="row">
                                        <!-- Dates select -->
                                        <div class="col-12">
                                            <h5 class="text-secondary mt-3"> Fecha y hora de la consulta </h5>
                                            <div class="info-block shadow-sm row m-0">
                                                <div class="col-6 center-flex">
                                                    <p class="m-0 mr-2 txt-main">
                                                        <mat-icon>calendar_today_outline</mat-icon>
                                                    </p>
                                                    <span class="text-secondary"> {{b.start_at | date:'mediumDate' }}
                                                    </span>
                                                </div>
                                                <div class="col-6 center-flex">
                                                    <p class="m-0 mr-2 txt-main">
                                                        <mat-icon>access_time</mat-icon>
                                                    </p>
                                                    <span class="text-secondary"> {{b.start_at | date:'shortTime'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="b.status == 'active'" class="col-12">
                                <button class="btn btn-primary bg-main col-12 my-2 text-white" (click)="goChat(b)">
                                    <mat-icon>chat_outline</mat-icon> chat
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="showGetMore">
                    <div class="col-12">
                        <button type="button" mat-button class="bg-main full-width text-white get-more" (click)="getBookingsPast()">
                            <span *ngIf="!isLoading"> Ver más </span>
                            <span *ngIf="isLoading" > Cargando ... </span>
                        </button>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Por Cancelar">
          <div class="container-fluid">
            <div class="row">
                <div *ngFor="let b of bookingsCancel" class="row col-xl-4 col-12 content-info my-4">
                    <div class="col-12 row mx-auto border">
                        <div class="row col-12 mt-3">
                            <div class="col-auto">
                                <img [src]="b.patient.photo_media_id ? b.patient.photo_media_id.url : './assets/img/profile.jpg'"
                                    class="rounded-circle avatar" alt="avatar">
                            </div>
                            <div class="col-8" *ngIf="!b.extra_firstname">
                                <h3> <b> {{b.patient_user_id.first_name}} {{b.patient_user_id.last_name}} </b> </h3>
                                <p class="m-0 txt-main"> {{b.category_name || 'categoria'}} </p>
                            </div>
                            <div class="col-8" *ngIf="b.extra_firstname">
                                <h3> <b> {{b.extra_firstname}} {{b.extra_lastname}} </b> </h3>
                                <p class="m-0 txt-main"> {{b.category_name || 'categoria'}} </p>
                            </div>
                            <div class="col-auto">
                                <span class="badge"
                                    [ngClass]="{'badge-danger': b.status == 'to_be_cancelled_by_therapist'}">{{'Pendiente de cancelar'}}</span>
                            </div>
                        </div>
                        <div class="col-12 row align-items-center">
                            <div class="col-12 p-4">
                                <mat-divider></mat-divider>
                                <div class="row">
                                    <!-- Dates select -->
                                    <div class="col-12">
                                        <h5 class="text-secondary mt-3"> Fecha y hora de la consulta </h5>
                                        <div class="info-block shadow-sm row m-0">
                                            <div class="col-6 center-flex">
                                                <p class="m-0 mr-2 txt-main">
                                                    <mat-icon>calendar_today_outline</mat-icon>
                                                </p>
                                                <span class="text-secondary"> {{b.start_at | date:'mediumDate' }}
                                                </span>
                                            </div>
                                            <div class="col-6 center-flex">
                                                <p class="m-0 mr-2 txt-main">
                                                    <mat-icon>access_time</mat-icon>
                                                </p>
                                                <span class="text-secondary"> {{b.start_at | date:'shortTime'}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="b.status == 'active'" class="col-12">
                            <button class="btn btn-primary bg-main col-12 my-2 text-white" (click)="goChat(b)">
                                <mat-icon>chat_outline</mat-icon> chat
                            </button>
                        </div>
                        <div *ngIf="b.status == 'to_be_cancelled_by_therapist'"
                        class="col-12 text-center row justify-content-around p-0 m-0">
                        <button class="btn btn-primary bg-danger col-xl-5 col-12 my-2 text-white"
                           (click)="cancelAppointment(b)" > Cancelar </button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </mat-tab>
    </mat-tab-group>
</section>
<div class="loading" *ngIf="isLoading" >
    <mat-spinner></mat-spinner>
</div>
